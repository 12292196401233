import React from "react";
import Theme from "../../../Resources/Theme";
import document from "../../../Assets/Image/document.png";
import story from "../../../Assets/Image/story.png";
import chart from "../../../Assets/Image/chart.png";
import folderopen from "../../../Assets/Image/folderopen.png";
import documenttext from "../../../Assets/Image/documenttext.png";
import profile from "../../../Assets/Image/profile.png";
import setting from "../../../Assets/Image/setting.png";
import Avatar from "../../../Assets/Image/Avatar.png";
import { NavLink } from "react-router-dom";

export const TopSidebar = ({
  short,
  activeClass,
  inactiveClass,
  permission,
}) => {
  const SideBarList = [
    permission?.has_case_access && {
      name: "Case List",
      icon: document,
      link: "/case/list/",
      link1: "/case/list/details/",
    },
    permission?.has_victim_access && {
      name: "Victims",
      icon: story,
      link: "/victim-details/",
    },
    permission?.has_analytics_access && {
      name: "Analytics",
      icon: chart,
      link: "/dashboard/",
    },
    permission?.has_files_access && {
      name: "Case files",
      icon: folderopen,
      link: "/case/files/",
    },
  ].filter(Boolean); // Ensure that the list only contains valid objects

  return (
    <>
      {SideBarList.map((item, index) => (
        <li key={index} className="py-2 relative">
          <NavLink
            to={item.link}
            className={({ isActive }) =>
              isActive ? `active_class ${activeClass}` : `${inactiveClass}`
            }
          >
            <span
              className={`flex  items-center gap-x-3 w-full py-2 ${
                short ? "px-6" : "px-5"
              }`}
            >
              <div className={`text-2xl ${short ? "text-mainGreen" : ""}`}>
                <img
                  src={item.icon}
                  alt={item.name}
                  className="w-[24px] h-[24px]"
                />
              </div>

              {/* Tooltip */}
              {short && <div className="tooltip">{item.name}</div>}

              {!short && <div className="text-normal">{item.name}</div>}
            </span>
          </NavLink>
        </li>
      ))}
    </>
  );
};

export const BottomSidebar = ({
  short,
  activeClass,
  inactiveClass,
  permission,
}) => {
  const SideBarList2 = [
    permission?.has_template_access && {
      name: "Templates",
      icon: documenttext,
      link: "/templates/",
    },
    permission?.has_partner_access && {
      name: "Partners",
      icon: profile,
      link: "/partner/",
    },
    permission?.has_settings_access && {
      name: "Settings",
      icon: setting,
      link: "/user/profile/",
    },
  ].filter(Boolean); // Ensure that the list only contains valid objects

  return (
    <>
      {SideBarList2.map((item, index) => (
        <li key={index} className="py-2 relative">
          <NavLink
            to={item.link}
            className={({ isActive }) =>
              isActive ? `active_class ${activeClass}` : `${inactiveClass}`
            }
          >
            <span
              className={`flex items-center gap-x-3 w-full py-2  ${
                short ? "px-6" : "px-5"
              }`}
            >
              <div className={`text-2xl ${short ? "text-mainGreen" : ""}`}>
                <img
                  src={item.icon}
                  alt={item.name}
                  className="w-[24px] h-[24px]"
                />
              </div>

              {/* Tooltip */}
              {short && <div className="tooltip">{item.name}</div>}

              {!short && <div className="text-normal">{item.name}</div>}
            </span>
          </NavLink>
        </li>
      ))}
    </>
  );
};

export const UserInfo = ({ short, user }) => {
  return (
    <>
      {!short && (
        <div className="border-t-1 border-b-1 border-[#152A40] py-5 mb-2 px-5">
          <div className="flex items-center gap-2">
            <img
              src={Theme.smalllogo}
              alt="logo"
              className="rounded-md w-[32px] h-[36px]"
            />
            <div className="flex flex-col">
              <span className="text-sm font-bold text-white">{user?.name}</span>
              <span className="text-sm text-[#5A789A] truncate max-w-[150px]">
                {user?.user}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
