import LogoSvg from "../Assets/Image/DarkLogo.svg";
import CaseLogo from "../Assets/Image/Icon/Case.svg";
import Email from "../Assets/Image/Icon/Email.svg";
import List from "../Assets/Image/Icon/List.svg";
import LoginLogo from "../Assets/Image/Icon/Login.svg";
import LogoDark from "../Assets/Image/Icon/LogoDark.svg";
import LogoWhite from "../Assets/Image/Icon/LogoWhite.svg";
import Negotiator from "../Assets/Image/Icon/Nego.svg";
import PlusSign from "../Assets/Image/Icon/PlusSign.svg";
import Question from "../Assets/Image/Icon/Question.svg";
import Register from "../Assets/Image/Icon/Register.svg";
import SaveExit from "../Assets/Image/Icon/SaveExit.svg";
import Logos from "../Assets/Image/Logos.png";
import smalllogo from "../Assets/Image/smalllogo.png";

import ActionIcon from "../Assets/Image/Icon/Icons/Action.png";
import DownloadIcon from "../Assets/Image/Icon/Icons/Download.png";
import DeleteIcon from "../Assets/Image/Icon/Icons/trash.png";
import UpdateIcon from "../Assets/Image/Icon/Icons/update.png";
const Theme = {
  Logo: "https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/logo.png",
  MainLogo:
    "https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/MainLogo1.png",

  LoginLogo: LoginLogo,
  Logos: Logos,
  smalllogo: smalllogo,
  CaseLogo: CaseLogo,
  LogoWhite: LogoWhite,
  LogoDark: LogoDark,
  Register: Register,
  Question: Question,
  PlusSign: PlusSign,
  SaveExit: SaveExit,
  Email: Email,
  LogoSvg: LogoSvg,
  List: List,
  Negotiator: Negotiator,

  Icons: {
    DeleteIcon: DeleteIcon,
    DownloadIcon: DownloadIcon,
    UpdateIcon: UpdateIcon,
    ActionIcon: ActionIcon,
  },
};

export default Theme;
