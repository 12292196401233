import React, { Suspense } from "react";
import { PublicPath, PrivatePath } from "./routes";
import Config from "../Config/Api";
import Loading from "../Screen/Loading/Loading";

async function fetchApiData(url, setLoading) {
  setLoading(true);
  try {
    const response = await fetch(url, Config.head);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  } finally {
    setLoading(false);
  }
}

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}
function RoutesLink() {
  const [loading, setLoading] = React.useState(true);
  const [userLoading, setUserLoading] = React.useState(false);
  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    demoAsyncCall().then(() => {
      if (Config.token) {
        getUsers().then(() => setLoading(false));
      } else {
        setLoading(false);
      }
    });
  }, []);

  const getUsers = async () => {
    const url = Config.apiUrl._permission;
    const response = await fetchApiData(url, setUserLoading);
    setUser(response);
  };

  if (loading || userLoading) {
    return <Loading />; // Display loading indicator during any loading phase
  }

  return (
    <Suspense fallback={<Loading />}>
      {Config.token ? <PrivatePath user={user} /> : <PublicPath />}
    </Suspense>
  );
}

export default RoutesLink;
