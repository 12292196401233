import { useGoogleLogout } from "@leecheuk/react-google-login";
import React from "react";
import Theme from "../../../Resources/Theme";
import { getRemoveToken } from "../../../Service/Auth/Auth";
import "./SideBar.css";
import SideMenu from "./SideMenu";

import left from "../../../Assets/Image/left.png";
import right from "../../../Assets/Image/right.png";
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
function SideBar({ user }) {
  const [short, setShort] = React.useState(false);

  const onFailure = () => {
    console.log("logout fail");
  };
  const onLogoutSuccess = () => {
    console.log("logout");
  };
  React.useEffect(() => {
    const savedShort = localStorage.getItem("sidebarShort");
    setShort(savedShort === "true");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to handle toggling the short mode of the sidebar
  const handleToggleShort = () => {
    const newShort = !short;
    localStorage.setItem("sidebarShort", newShort.toString());
    setShort(newShort);
  };

  const { signOut } = useGoogleLogout({
    clientId: googleClientId,
    onLogoutSuccess: onLogoutSuccess,
    onFailure: onFailure,
  });

  const onLogout = (e) => {
    e.preventDefault();
    try {
      getRemoveToken();
      signOut();
    } catch (error) {
      console.log(error);
    }
  };

  const permission = user?.permissions || {};

  return (
    <div className="">
      <div
        className={` relative h-screen  border-r-1 border-[#152A40] xssm:hidden tab:flex bg-[#061523]  ${
          short ? "w-20" : "w-60"
        }  flex-col justify-between   `}
      >
        <button
          onClick={handleToggleShort}
          className={`  ${
            short ? "absolute top-10 -right-3" : "absolute top-10 right-2"
          } z-10 transform  rounded-full p-1 cursor-pointer block `}
          title={short ? "Expand Sidebar " : "Collapse Sidebar"}
        >
          {short ? (
            <img src={left} alt="left" className="w-[24px] h-[24px]" />
          ) : (
            <img src={right} alt="right" className="w-[24px] h-[24px]" />
          )}
        </button>

        <div>
          <div className="flex items-center cursor-pointer px-5  py-3 ">
            <img
              src={short ? Theme.smalllogo : Theme.Logos}
              alt="Logo"
              className={`  ${short ? " mt-2  " : "pr-10 mt-4"}`}
            />
          </div>
          <SideMenu short={short} permission={permission} user={user} />
        </div>
      </div>
    </div>
  );
}

export default SideBar;
