import { useGoogleLogout } from "@leecheuk/react-google-login";
import React from "react";
import logout from "../../../Assets/Image/logout.png";
import { getRemoveToken } from "../../../Service/Auth/Auth";
import { BottomSidebar, TopSidebar, UserInfo } from "./Data";
import "./SideBar.css";

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
function SideMenu({ short, permission, user }) {
  const activeClass = " text-mainColor   ";
  const inactiveClass = "text-[#8FA8C3] opacity-80   ";

  const onFailure = () => {
    console.log("logout fail");
  };
  const onLogoutSuccess = () => {
    console.log("logout");
  };
  const { signOut } = useGoogleLogout({
    clientId: googleClientId,
    onLogoutSuccess: onLogoutSuccess,
    onFailure: onFailure,
  });

  const onLogout = (e) => {
    e.preventDefault();
    try {
      getRemoveToken();
      signOut();
    } catch (error) {
      console.log(error);
    }
  };

  const commonSpan = (icon, name, classes) => (
    <span className={`flex   gap-x-2 w-full items-center   ${classes}`}>
      <span className="text-2xl   ">
        <img src={icon} alt={name} className="w-[24px] h-[24px]" />
      </span>
      {!short && <span className="text-normal  relative    ">{name}</span>}
      {short && <span class="tooltip">{"Log out"}</span>}
    </span>
  );

  return (
    <div className={`${short ? "w-20" : "w-60"}   overflow-hidden`}>
      <ul
        className={`${
          short ? "w-20" : "w-60"
        } flex flex-col py-2 w-full  sidebar`}
      >
        <UserInfo short={short} user={user} />
        <span className="px-5 text-white text-xs mb-3 mt-2">Menu</span>
        <TopSidebar
          short={short}
          activeClass={activeClass}
          inactiveClass={inactiveClass}
          permission={permission}
        />

        <span className="px-5 text-white text-xs mt-8 mb-5">Others</span>
        <BottomSidebar
          short={short}
          activeClass={activeClass}
          inactiveClass={inactiveClass}
          permission={permission}
        />
        <li className="relative px-5">
          <button
            className={` py-2 ${
              short ? "w-20  text-mainGreen " : "w-60"
            }  ${inactiveClass} ${short ? "px-1" : "px-0"} `}
            onClick={(e) => onLogout(e)}
          >
            {commonSpan(logout, "Logout", "")}
          </button>
        </li>
      </ul>
    </div>
  );
}

export default SideMenu;
