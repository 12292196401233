import Cookies from "universal-cookie";

const cookie = new Cookies();
const authToken = cookie.get("@mosaicXToken");
const api = process.env.REACT_APP_PRODUCTION_V;
const mattr = process.env.REACT_MATTR_BASE;
const Config = {
  apiUrl: {
    _cord_update: `${api}users/template-update-cord/`,
    _logoutAPI: ` ${api}oauth/revoke_token/`,
    _login_api: `${api}users/oauth/token/admin/`,
    _due_diligence: `${api}case/request_due_diligence/`,
    _customer_login: `${api}users/customer/login/`,
    _customer_registration: `${api}users/register/customer/`,
    _update_customer: `${api}users/update/customer/`,
    _updated_negotiator: `${api}users/update-nego-forensic/`,
    _nego_updated_case: `${api}case/nego-update-case/`,
    _victim_update: `${api}case/victim-update/`,
    _create_case: `${api}case/create/`,
    _case_check: `${api}case/case-checks/`,
    _case_file_list: `${api}case/list/`,
    _victim_update_case: `${api}case/victim-update-case/`,
    _dashboard_case_list: `${api}case/dashboard/`,
    _confirm_otp: `${api}users/confirm-code/`,
    _staff_case_list: `${api}case/all-case/`,
    _staff_case_malware: `${api}case/all-case/malware/`,
    _staff_case_cda: `${api}case/all-case/attribution/`,
    _case_data: `${api}case/customer-case/`,
    _customer_update: `${api}case/customer_update/`,
    _staff_widgets: `${api}case/staff-widgets/`,
    _partner_widgets: `${api}case/partner-widgets/`,
    _send_threat_actor: `${api}case/top-threat/`,
    _mattr_case_details: `${mattr}get/case/<uuid>/`,
    _create_case_file: `${api}case/create-case/image/`,
    _user_date: `${api}users/me/`,
    _send_hubspot: `${api}case/send-hubspot/`,
    _service_list: `${api}invoice/service/`,
    _upload_file: `${api}case/create-document/`,
    _send_file: `${api}case/send-document/`,
    _report_list: `${api}users/report-list/`,
    _create_report: `${api}case/extra-sing/`,
    _additional_info: `${api}case/victim-impact/`,
    _edit_sign_document: `${api}case/update-case-template/`,
    _pull_data: `${api}case/fetch-date/`,
    _sent_email: `${api}users/send-join-email/`,
    _attestation: `${api}case/update-attestation/`,
    _download_file: `${api}case/download-attestation/`,
    _add_hash: `${api}case/add-transaction/`,
    _case_list: `${api}case/all-case-list/`,
    _update_invoice: `${api}invoice/update-invoice/`,
    _document_list: `${api}case/all-case-list-document/`,
    _update_hedge: `${api}case/update-hedge/`,
    _base_fees: `${api}invoice/base-fees/`,
    _update_bsa_file: `${api}data/create-sftx-case/`,
    _case_fees: `${api}invoice/case-fees/`,
    _new_case_fees: `${api}invoice/new-invoice/`,
    _partner: `${api}users/partner/`,
    _partner_create_case: `${api}case/create-partner-case/`,
    _get_fees: `${api}invoice/get-fees/`,
    _create_send_fees: `${api}invoice/create-invoice/`,
    _email_template: `${api}users/email-template/`,
    _dropdown: `${api}data/dropdown/`,
    _create_template: `${api}users/create-email-template/`,
    _dry_run: `${api}case/dry-run/`,
    _pull_hello_data: `${api}case/hello-pull/`,
    _pull_mati_status: `${api}users/mati-status/`,
    _send_survey: `${api}feedback/create-survey/`,
    _check_survey: `${api}feedback/check-survey/`,
    _fill_survey: `${api}feedback/fill-survey/`,
    _update_new_contract: `${api}case/update-document/`,
    _message: `${api}data/email-template/`,
    _block_case: `${api}users/block-unblock-case/`,
    _success_screen: `${api}users/success-screen/`,
    _request_otp: `${api}users/request-code/`,
    _invoice_update: `${api}invoice/update-invoice-satus/`,
    _attestation_list: `${api}case/attestation-impact/`,
    _threat_lists: `${api}case/attestation-threat/`,
    _create_attestation: `${api}case/create-attestation/`,
    _create_table_view: `${api}data/list-create-view/`,
    _search_wallet: `${api}case/wallet-search/`,
    _update_fees: `${api}invoice/update_invoice/`,
    _update_request_settlement: `${api}case/request_settlement/`,
    _update_wallet: `${api}case/update-wallet/`,
    _create_update_wallet: `${api}case/bsa-text/`,
    _update_bsa_text: `${api}case/upated-bsa-text/`,
    _generate_xml: `${api}case/generate-xml/`,
    _upload_file_xml: `${api}case/send-bsa-file/`,
    _check_inbox: `${api}case/check-inbox/`,
    _update_mati_status: `${api}users/update-mati-status/`,
    _remove_contract: `${api}case/remove-contact/`,
    _info_contract: `${api}users/info/`,
    _upload_signed_contract: `${api}case/upload-signed-contact/`,
    _upload_invoice: `${api}invoice/upload_invoice/`,
    _update_invoice_status: `${api}invoice/update_status/`,
    _download_cda_report: `${api}case/download-cda-report/`,
    _law: `${api}users/law/`,
    _permission: `${api}users/permission/`,
    _create_update_user: `${api}users/create-user/`,
    _victim_list: `${api}users/victim-list/`,
    _update_global_hello_sign: `${api}case/update-hello-sign/`,
    _new_partner: `${api}users/partner-list/`,
    _negotiator: `${api}case/negotiator-case/`,
    _forensic: `${api}case/forencis-case/`,
    _reset_paid: `${api}case/reset-mark-paid/`,
    _follow_up_email: `${api}feedback/follow-up-email/`,
    _update_survey: `${api}feedback/update-survey/`,
    _case_search: `${api}users/case-search/`,
    _case_filter: `${api}users/case-filter/`,
    _secondary_email: `${api}feedback/secondary-email/`,
    _convert_case: `${api}case/convert-case/`,
    _case_stats: `${api}case/case-stats/`,
  },

  token: authToken,

  head: {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer " + authToken,
    },
  },

  headOutsource: {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer TokenUsedforDRK_MATTR",
    },
  },

  headeJust: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + authToken,
  },

  headeJustnonAuth: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export default Config;
