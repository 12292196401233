export const baseUrl = process.env.PUBLIC_URL;
// eslint-disable-next-line
export default {
  // Public Path
  // _homePage: baseUrl + "/",
  _login: baseUrl + "/",
  _case_register: baseUrl + "/case/register/",
  _customer_register: baseUrl + "/customer/register/",
  _case_login: baseUrl + "/customer/login/",
  _update_data: baseUrl + "/update-data/:type/",
  _nego_foren: baseUrl + "/update-case-data/:type/",

  // Private Path
  _dashboard: baseUrl + "/dashboard/",
  _case_details: baseUrl + "/case-details/",
  _edit_case: baseUrl + "/edit-case/",
  _case_list: baseUrl + "/case/list/",
  _case_list_details: baseUrl + "/case/list/details/",
  _user_details: baseUrl + "/user/profile/",
  _additional_details: baseUrl + "/victim-impact-statement/",
  _templates: baseUrl + "/templates/",
  _case_file: baseUrl + "/case/files/",
  _case_file_details: baseUrl + "/case/files/details/",
  _partner: baseUrl + "/partner/",
  _add_partner: baseUrl + "/partner/add/",
  _victim_details: baseUrl + "/victim-details/",
  _add_law_firm: baseUrl + "/law/add/",
  _coordinates: baseUrl + "/coordinates/",
};
