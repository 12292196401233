import React from "react";
// import Config from "../../Config/Api";
import Error from "../../Assets/Image/NotFound/404.svg";
function NotFound() {
  // active:bg-blue-600 hover:bg-blue-700

  const OnClickBack = () => {
    window.location.href = "/";
  };

  // if (Config.token) {
  //   window.location.href = "/";
  // }

  // const token = Config?.token ? true : false;
  // class={`min-h-screen  w-screen overflow-hidden -ml-10  flex items-center ${
  //       token ? "w-11/12 pl-16" : ""
  //     }`}

  return (
    <div class="min-w-screen min-h-screen  flex items-center p-5 lg:p-20 overflow-hidden relative bg-SideDark text-white">
      <div class="flex-1 min-h-full min-w-full rounded-3xl bg-black  shadow-xl border-1 border-gray-700 p-10 lg:p-20  relative md:flex items-center text-center md:text-left">
        <div class="w-full md:w-1/2">
          <div class="mb-10 lg:mb-20">
            <h1 className="text-6xl  font-semibold tracking-widest  ">LOGO </h1>
          </div>
          <div class="mb-10 md:mb-20 text-white font-light">
            <h1 class="font-black uppercase text-3xl lg:text-5xl text-light600 mb-10">
              You seem to be lost!
            </h1>
            <p>The page you're looking for isn't available.</p>
            <p>Try searching again or use the Go Back button below.</p>
          </div>
          <div class="mb-20 md:mb-0">
            <button
              onClick={() => OnClickBack()}
              class="text-lg font-light outline-none focus:outline-none transform transition-all hover:scale-110 text-light500 focus:text-light600"
            >
              <i class="mdi mdi-arrow-left mr-2"></i>Go Back
            </button>
          </div>
        </div>
        <div class="w-full md:w-1/2 text-center">
          <img src={Error} class="" alt="" />
        </div>
      </div>
      <div class="w-64 md:w-96 h-96 md:h-full bg-green-200 bg-opacity-30 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform"></div>
      <div class="w-96 h-full bg-mainGreen bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45 transform"></div>
    </div>
  );
}

export default NotFound;
