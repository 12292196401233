import React from "react";
import "./Loading.css";
// import spinner from "../../Assets/Images/Loader/loader.gif";
function Loading() {
  return (
    <div className="flex h-screen  bg-mainblue-900 justify-center  py-10   ">
      <div class="dot"></div>
    </div>
  );
}

export default Loading;
