import NotFound from "../Screen/NotFound"; // Ensure this is correctly imported

/**
 * Helper function to conditionally render components based on user permissions
 * @param {React.FC<{ user: User }>} Component - The component to render if permission check passes
 * @param {(user: User) => boolean} permissionCheck - The function to check user permissions
 * @returns {(user: User) => JSX.Element} - A function that takes a user and returns a component or NotFound
 */
const conditionalComponent = (Component, permissionCheck) => {
  return (user) =>
    permissionCheck(user) ? (
      <Component permission={user} />
    ) : (
      <NotFound user={user} />
    );
};

// Permission check functions based on the JSON structure
const hasCaseAccess = (user) => user?.permissions?.has_case_access;
const hasCaseOnboardingAccess = (user) =>
  user?.permissions?.has_case_onboarding_access;
const hasCaseDueDiligenceAccess = (user) =>
  user?.permissions?.has_case_due_diligence_access;
const hasCaseTransactionAccess = (user) =>
  user?.permissions?.has_case_transaction_access;
const hasCaseAttestationAccess = (user) =>
  user?.permissions?.has_case_attestation_access;
const hasCaseWalletAccess = (user) => user?.permissions?.has_case_wallet_access;
const hasCaseContractAccess = (user) =>
  user?.permissions?.has_case_contract_access;
const hasCaseAttestationGenerateAccess = (user) =>
  user?.permissions?.has_case_attestation_generate_access;
const hasCaseInvoiceAccess = (user) =>
  user?.permissions?.has_case_invoice_access;
const hasCaseRegulatoryAccess = (user) =>
  user?.permissions?.has_case_regulatory_access;
const hasCaseVictimAccess = (user) => user?.permissions?.has_case_victim_access;
const hasCaseViewAccess = (user) => user?.permissions?.has_case_view_access;
const hasCaseEditAccess = (user) => user?.permissions?.has_case_edit_access;
const hasCaseBsaAccess = (user) => user?.permissions?.has_case_bsa_access;
const hasVictimAccess = (user) => user?.permissions?.has_victim_access;
const hasAnalyticsAccess = (user) => user?.permissions?.has_analytics_access;
const hasTemplateAccess = (user) => user?.permissions?.has_template_access;
const hasPartnerAccess = (user) => user?.permissions?.has_partner_access;
const hasFilesAccess = (user) => user?.permissions?.has_files_access;
const hasSettingsAccess = (user) => user?.permissions?.has_settings_access;
const hasMarkPaidAccess = (user) => user?.permissions?.has_mark_paid_access;

export {
  conditionalComponent,
  hasCaseAccess,
  hasCaseOnboardingAccess,
  hasCaseDueDiligenceAccess,
  hasCaseTransactionAccess,
  hasCaseAttestationAccess,
  hasCaseWalletAccess,
  hasCaseContractAccess,
  hasCaseAttestationGenerateAccess,
  hasCaseInvoiceAccess,
  hasCaseRegulatoryAccess,
  hasCaseVictimAccess,
  hasCaseViewAccess,
  hasCaseEditAccess,
  hasCaseBsaAccess,
  hasVictimAccess,
  hasAnalyticsAccess,
  hasTemplateAccess,
  hasPartnerAccess,
  hasFilesAccess,
  hasSettingsAccess,
  hasMarkPaidAccess,
};
