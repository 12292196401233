import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "tailwindcss/tailwind.css";
import RoutesLink from "./router";
import "./App.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RoutesLink />
  </React.StrictMode>
);

// replace console.* for disable log on production      AIzaSyAJIsQq2P13hvXC12s7bdzvyb9btODQQNU
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
reportWebVitals();
console.clear();
