import React from "react";
import SideBar from "../SideBar";
function MainLayout({ children, user }) {
  return (
    <div className="flex h-screen  bg-mainblue-900">
      <div className="overflow-auto no-scrollbar">
        <SideBar user={user} />
      </div>
      <div className="flex-1 flex w-[100%] overflow-auto flex-col no-scrollbar">
        <div className="">{/* <TopsHeader user={user} /> */}</div>
        <div className="   relative bg-white dark:text-white text-black">
          {children}
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
