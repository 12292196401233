import { Suspense } from "react";
import { Route, Navigate, BrowserRouter, Routes } from "react-router-dom";
import routesPath from "./RouterPath";
import ErrorBoundary from "../Screen/ErrorBoundry/Error";
import Cookies from "universal-cookie";
import SLUGS from "../Resources/Slug/Slug";
import Loading from "../Screen/Loading/Loading";
import MainLayout from "../Components/Layout/MainLayout";

const cookies = new Cookies();

const isAuthenticated = cookies.get("@mosaicXToken");

function RequireAuth({ children, redirectTo }) {
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function NotRequireAuth({ children, redirectTo }) {
  return isAuthenticated ? <Navigate to={redirectTo} /> : children;
}

export const PublicPath = () => (
  <BrowserRouter>
    <Suspense fallback={<Loading />}>
      <Routes>
        {routesPath.Public.map((c, i) => (
          <Route
            path={c.path}
            key={i + 1}
            element={
              <NotRequireAuth redirectTo={SLUGS._homePage}>
                <ErrorBoundary>{c.component}</ErrorBoundary>
              </NotRequireAuth>
            }
          ></Route>
        ))}
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export const PrivatePath = ({ user }) => (
  <BrowserRouter>
    <Suspense fallback={<Loading />}>
      <MainLayout user={user}>
        <Routes>
          {routesPath?.Private?.filter(Boolean)?.map((c, i) => (
            <Route
              path={c.path}
              key={i + 1}
              element={
                <RequireAuth redirectTo={SLUGS._case_list}>
                  <ErrorBoundary>{c.component(user)}</ErrorBoundary>
                </RequireAuth>
              }
            ></Route>
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </MainLayout>
    </Suspense>
  </BrowserRouter>
);
