import { lazy } from "react";
import SLUGS from "../Resources/Slug/Slug";
import NotFound from "../Screen/NotFound";
import {
  conditionalComponent,
  hasAnalyticsAccess,
  hasCaseAccess,
  hasFilesAccess,
  hasPartnerAccess,
  hasSettingsAccess,
  hasTemplateAccess,
  hasVictimAccess,
} from "./helper";

/* 
==========================================
 Public Router Path
==========================================
*/

const Login = lazy(() => import("../Screen/Auth/Login"));
const Register = lazy(() => import("../Screen/Case/Register"));
const CustomerRegister = lazy(() => import("../Screen/Customer/Register"));
const CaseLogin = lazy(() => import("../Screen/Case/Login"));
const NegoForen = lazy(() => import("../Screen/Case/Form"));

/* 
==========================================
 Private Router Path
==========================================
*/

const Home = lazy(() => import("../Screen/Home"));
const List = lazy(() => import("../Screen/Case/List"));
const Details = lazy(() => import("../Screen/Case/Details"));
const User = lazy(() => import("../Screen/User"));
const Additional = lazy(() => import("../Screen/Additional"));
const Template = lazy(() => import("../Screen/Template"));
const Files = lazy(() => import("../Screen/Files"));
const AllFiles = lazy(() => import("../Screen/Files/Details"));
const Main = lazy(() => import("../Screen/Partner"));
const AddPartner = lazy(() => import("../Screen/Partner/Add"));
const Victim = lazy(() => import("../Screen/Victims"));
const AddFirm = lazy(() => import("../Screen/Partner/Law/Add"));
const Cordinates = lazy(() =>
  import("../Screen/Template/Document/Section/Cordinates")
);
const routesPath = {
  // Public Router Path without Authentication
  Public: [
    {
      path: SLUGS._login,
      component: <Login />,
    },
    {
      path: SLUGS._case_register,
      component: <Register />,
    },
    {
      path: SLUGS._customer_register,
      component: <CustomerRegister />,
    },
    {
      path: SLUGS._case_login,
      component: <CaseLogin />,
    },
    {
      path: SLUGS._additional_details,
      component: <Additional />,
    },
    {
      path: SLUGS._nego_foren,
      component: <NegoForen />,
    },
    {
      path: "*",
      component: <NotFound />,
    },
  ],
  // Private Router Path with Authentication
  Private: [
    {
      path: SLUGS._dashboard,
      component: (user) => conditionalComponent(Home, hasAnalyticsAccess)(user),
    },
    {
      path: SLUGS._case_list,
      component: (user) => conditionalComponent(List, hasCaseAccess)(user),
    },
    {
      path: SLUGS._case_list_details,
      component: (user) => conditionalComponent(Details, hasCaseAccess)(user),
    },
    {
      path: SLUGS._user_details,
      component: (user) => conditionalComponent(User, hasSettingsAccess)(user),
    },
    {
      path: SLUGS._templates,
      component: (user) =>
        conditionalComponent(Template, hasTemplateAccess)(user),
    },
    {
      path: SLUGS._case_file,
      component: (user) => conditionalComponent(Files, hasFilesAccess)(user),
    },
    {
      path: SLUGS._case_file_details,
      component: (user) => conditionalComponent(AllFiles, hasFilesAccess)(user),
    },
    {
      path: SLUGS._partner,
      component: (user) => conditionalComponent(Main, hasPartnerAccess)(user),
    },
    {
      path: SLUGS._add_partner,
      component: (user) =>
        conditionalComponent(AddPartner, hasPartnerAccess)(user),
    },
    {
      path: SLUGS._victim_details,
      component: (user) => conditionalComponent(Victim, hasVictimAccess)(user),
    },
    {
      path: SLUGS._add_law_firm,
      component: (user) =>
        conditionalComponent(AddFirm, hasPartnerAccess)(user),
    },
    {
      path: SLUGS._coordinates,
      component: (user) =>
        conditionalComponent(Cordinates, hasPartnerAccess)(user),
    },
    {
      path: "*",
      component: (user) =>
        conditionalComponent(NotFound, hasPartnerAccess)(user),
    },
  ],
};

export default routesPath;
